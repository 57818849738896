<template>
  <template v-if="blok.title?.length || blok.iconName?.length || text?.length">
    <template
      v-if="
        !blok.is_uncrawlable &&
        (story?.full_slug?.length ||
          blok.link?.url?.length ||
          blok.link?.story?.url?.length)
      "
    >
      <NuxtLink
        :to="strippedURL(story?.full_slug || blok.link)"
        :title="blok.title"
        :target="
          blok.link.target || blok.link.linktype === 'url' ? '_blank' : ''
        "
        class="editorial-attribute"
      >
        <SvgIcon :name="blok.iconName" />
        <div class="editorial-attribute-content">
          <h3 v-if="blok.title" class="size-m">{{ blok.title }}</h3>
          <div v-if="text?.length" class="size-s" v-html="text"></div>
        </div>
        <p class="bold editorial-attribute-cta">{{ useMicroCopy("editorial.cta.label") }}</p>
      </NuxtLink>
    </template>
    <template v-else>
      <div
        :title="blok.title"
        :role="
          story?.full_slug ||
          blok.link?.url?.length ||
          blok.link?.story?.url?.length
            ? 'button'
            : null
        "
        class="editorial-attribute"
        @click="handleClick(story?.full_slug || blok.link)"
      >
        <SvgIcon :name="blok.iconName" />
        <div class="editorial-attribute-content">
          <h3 v-if="blok.title" class="size-m">{{ blok.title }}</h3>
          <div v-if="text?.length" class="size-s" v-html="text"></div>
        </div>
        <p
          v-if="
            story?.full_slug?.length ||
            blok.link?.url?.length ||
            blok.link?.story?.url?.length
          "
          class="bold editorial-attribute-cta"
        >
          {{ useMicroCopy("editorial.cta.label") }}
        </p>
      </div>
    </template>
  </template>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const handleClick = (link) => {
  if (link?.length || link?.url?.length || link?.story?.url?.length) {
    const options =
      link?.linktype === "url"
        ? {
            external: true,
            open: {
              target: "_blank",
            },
          }
        : link?.target
        ? {
            open: {
              target: link?.target,
            },
          }
        : null;
    navigateTo(strippedURL(link), options);
  }
};

const config = useRuntimeConfig();
const storyblokApi = useStoryblokApi();
let story = null;

if (props.blok.attribute_key?.length) {
  story = await storyblokApi
    .get("cdn/stories", {
      version: config.public["storyblokVersion"],
      content_type: "service-page",
      starts_with: `${config.public["storyblokCountryPrefix"]}`,
      filter_query: {
        attribute_key: { is: "not_empty", in: props.blok.attribute_key },
      },
    })
    .then((response) => {
      return response.data.stories[0];
    })
    .catch((error) => {
      console.log(error);
    });
}

const text = computed(() => {
  return story
    ? useRenderRichText(story?.content?.overview_description)
    : useRenderRichText(props.blok.text);
});
</script>

<style lang="scss" scoped>
.editorial-attribute {
  display: flex;
  padding: 2rem 2.5rem 2rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 2.5rem;
  border-radius: $radius-l;
  min-width: 18rem;
  text-decoration: none;
  @include pair-3;
  &[role="button"] {
    cursor: pointer;
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    height: calc(100% - 5.375rem);
  }
  &-cta {
    text-decoration: underline;
  }
}
.alternate .editorial-attribute {
  @include pair-7;
}
</style>
